import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/gaso_blog_header.png");
const section_1 = require("../../../assets/img/blogs/gaso_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/gaso_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/gaso_blog_img_3.gif");
const section_4 = require("../../../assets/img/blogs/gaso_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/gaso_blog_img_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Empowering Service Desk Managers:The Role of Generative AI in Support Operations"
        description="Generative AI helps you reimagine your mundane service desk operations with many effective use cases. Empower your service desk managers with GenAI today. "
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt gaso_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-normal">
                    Empowering Service Desk Managers:{" "}
                    <span className="font-page-header-home-blog">
                      The Role of Generative AI in Support Operations
                    </span>
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-normal">
                    Empowering Service Desk <br /> Managers:{" "}
                    <span className="font-page-header-home-blog">
                      The Role of <br /> Generative AI in Support
                      <br /> Operations
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Generative AI in service desk support operations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How can Generative AI empower service desk managers with
                  service desk operations?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Benefits of Generative AI for service desk operations
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Workative X Generative AI for Service Desk Managers
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Conclusion:
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk operations aren’t level-one services similar to the
                helpdesk that customers or internal users need from a service
                desk. It is not that the service desk does not handle simple
                operations, but the service desk is mostly known for handling
                more complex operations using streamlined workflows 一 and of
                course, focusing on cost optimization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For service desk managers, cost optimization is a key metric
                that helps determine how effectively and efficiently they manage
                service desk operations without compromising service quality.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, it means the right person handles the ticket,
                provides the right support, and helps in business continuity at
                the right time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This is how we can visualize a good service desk to be.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, the more time it takes to solve a service desk ticket, the
                more costs it adds to per ticket.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The bottom line is service desk managers’ aim is to streamline
                service desk operations and ensure cost-effectiveness for the
                business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a well-known fact that CIOs have long started leveraging
                artificial intelligence, given AI provides a broader aspect of
                managing tasks more meaningfully and efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to the 2023 Gartner CIO and Technology Executive
                Survey,{" "}
                <a href="https://www.gartner.com/en/articles/what-cios-need-to-know-about-deploying-ai">
                  15% of CIOs surveyed are eager to deploy AI within the next
                  year. It wouldn’t be wrong to say that Generative AI can be
                  the next AI
                </a>{" "}
                target for these CIOs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk, being a core component for any enterprise to
                ensure business resilience and continuity, CIOs also hold a
                responsibility to empower service desk managers with adequate
                tools and technologies that help streamline service desk
                operations and ensure cost-effectiveness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Alike many business leaders, if AI is your next big target, why
                not Generative AI for your service desk managers?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI in service desk support operations
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let's look at the preliminary side of service desk management.
                It is hard to deny the importance of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation">
                  IT resources or knowledge bases
                </a>{" "}
                that comes in handy for users to get out of trouble by
                themselves or assist others 一 at least in a way that a ticket
                gets resolved in Tier-0 or Tier-1. Effective IT resources can
                reduce calls or emails to the service desk, giving
                auto-resolution capabilities to the users.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a legacy model, knowledge resources are not centralized. It
                is even more painful when knowledge is out of date.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The content generation capability can be applied to the IT
                service desk to keep knowledge bases updated. This is just one
                use case of Generative AI for service desk managers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The complex nature of the service desk, where a growing volume
                of tickets is a regular scene, followed by delayed resolution of
                a problem and those repetitive service requests, is only getting
                more complex.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI provides end-to-end management capabilities for
                service desk managers, from IT resource development and
                self-service augmentation through personalized chatbot
                interactions to ticket handling, incident management, and more.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can Generative AI empower service desk managers with service
                desk operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a unique innovation of the decade that barely
                needs any convincing explanation as to why industries must use
                it for various use cases. It has already brought a paradigm
                shift to the forefront of the industry and massive disruption to
                how work gets done.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to dive deep to explore Generative AI, our article
                on “Generative AI for Dummies” may be quite useful for you to
                understand this emerging AI technology.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now let us take you through how Generative AI can transform how
                service desk managers handle operations all these years.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Augment the capacity of conversational AI solutions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What you see is that the conversational AI capability of a
                chatbot is independent. A{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational automation platform
                </a>{" "}
                or engine harnesses natural language processing or natural
                language understanding to extract intents and entities from
                structured or unstructured conversations of users and offer a
                contextual response.
              </p>
              <BlogCta
                ContentCta="Optimize Your IT Support Opereations with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The conversational AI can answer unstructured conversation
                threads by adapting to the environment and continuously
                self-learning.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The conversational experience can be even more personalized and
                straightforward for users to solve their problems when NLP and
                NLU of conversational automation engines are applied with the
                power of Large Language Models (LLMs).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, when you use Generative AI for your conversational AI
                platform or VA for your users, you can offer enriched responses
                that are not bland, yet fast to absorb and meaningful enough to
                solve a problem without the need to seek help from top tiers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, a regular answer from a conversational AI can
                appear something like below:
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Regular conversation with a conversational AI solution"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                The suggestion is helpful. But, the LLM-powered enriched
                response sound so human-like and personal.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="LLM-powered conversations between a user and a bot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                When LLM-powered responses can feel more human and relevant, it
                usually cuts off human dependency and helps solve user problems.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Hyper-automated response suggestions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user question is complicated concerning a product.
                Junior-level support associates likely need help from
                experienced ones. This is again a costly involvement from senior
                level staff and a pain for service desk managers on the cost
                side.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI helps remove this bottleneck from the agent-user
                journey and provides an easy template for the agent to provide
                contextual responses to users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this context, agents can use LLMs to auto-generate responses
                related to product-specific questions and offer an improved
                suggestion. With the ability to fetch intents from previous
                conversations, Generative AI helps maintain brand tone in the
                ongoing chat session and helps solve customer requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this example, let’s represent how a Generative AI-powered
                service desk can automate response and provide the right
                suggestion.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="LLM-powered conversations between a user and a bot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Another flexibility is that the agent can make some improvements
                to the draft before sending the final response. This is a way
                response automation saves time for agents and helps close the
                tickets rapidly.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Real-time incident management
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="incident management using a Generative AI service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Chances are AIOps can come up with vague clarity due to a lack
                of appropriate data when prompted to suggest an action plan.
                (Though it is solely related to ITSM, we relate to this context
                here because the service desk is a subset of ITSM.)
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But for service desk managers, incident handling becomes easy
                when they harness Generative AI.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium">
                Generation of incident titles and summaries:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Creating incident titles and summaries is something that needs
                deep evaluation so that requesters can relay the right
                information and reduce information fatigue. With the ability to
                generate correct content for the titles and summaries,
                Generative AI helps requesters reduce time to create content and
                log the incident.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium">
                Incident analysis and handover for mitigation:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                When an incident is logged in, Generative AI can use an intent
                classifier to synthesize historic incident data and unstructured
                data to identify which incident is to prioritize based on the
                emergency and escalate to the right agent. Using components like
                a deep convolutional neural network, NLU, and intent classifier
                with entity extractors, Generative AI works better than any
                traditional AIOps model to categorize and prioritize incidents.
                Another high-level capability of Generative AI is that it can
                also recommend incident mitigation action plans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This unique automation capability in the entire workflow of
                incident management reduces the workloads on the service desk
                managers, giving them more time and room to work on critical
                incidents.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automated workflows for service request management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is a huge volume of tickets every day to the service desk
                一 most of them repetitive and mundane. Yet, the service desk
                must spend a lot of time resolving these issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just imagine a situation when an agent does not show up. It
                takes a toll on other agents and adds extra pay for prolonged
                hours of agent utilization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can be used to augment service request management
                by streamlining these manual workflows using automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We already discuss the enhanced capability of a chatbot above.
                To implement and enhance self-service capabilities for the
                service desk chatbot, Generative AI can be instrumental in
                fostering user interest in the tool to resolve a service desk
                issue autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service requests, such as hardware replacement, software
                installation, or asset provisioning, are repetitive. If you can
                streamline workflows for these tasks, your users can handle them
                easily in the very tier-0 stage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a user wants his old laptop replaced.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Instead of seeking the manager’s consent, writing to HR, and
                then communicating back to the inventory department, an
                automated workflow for a Generative-AI powered chatbot can be
                convenient and intuitive.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Flexible IT support for internal employees "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                In this instance, Generative AI is integrated with ERP and many
                other tools in the backend to fetch the real-time stock status
                to the chatbot interface and provide the users with the right
                product information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is how easily Generative AI helps employees get work done
                faster.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Problem management through enhanced root cause analysis
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the key areas of service desk management is to take care
                of problem management so that managers can prepare themselves
                for unexpected incidents to resolve them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI allows service desk managers to harness data and
                prepare analytics reports that facilitate root cause analysis of
                an incident. This is helpful for learning across the incident
                patterns and visualizing the future incident's tendency. As a
                result, service desk managers can allocate resources and be
                prepared to mitigate incidents ahead of time.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Seamless service level agreement management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Every workflow in incident management must have the right
                synchronization of connectivity, response, and service delivery.
                For example, if there is a delay in incident identification or
                categorization, the predefined activity in the next of the
                entire workflow gets hampered and impacts service level
                agreement. Unfortunately, this ends up increasing MTTR and
                prolongs downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI helping you manage everything from user
                interaction to chatbot response delivery in a fast-paced service
                desk landscape, you are more empowered to take the situation
                under control and reinstate operational efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of Generative AI for service desk operations
              </h2>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Streamlined workflows -
                  </span>{" "}
                  Your service desk operations become more streamlined and
                  aligned. Both customers and internal users can experience the
                  flexibility of self-service capabilities, which reduces manual
                  dependencies and offer more straightforward solutions to
                  business problems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Cost-effectiveness -
                  </span>{" "}
                  As a CIO or business leader of your organization, you can
                  notice your service desk managers are more capable of handling
                  more critical incidents or other service requests steadily.
                  The cost of ticket handling abruptly comes down and encourages
                  bottom-line growth.
                </li>
                <li className="font-section-normal-text-testimonials mb-0">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Helping attain business goals:
                  </span>{" "}
                  Service desk managers must ensure internal users or customers
                  get what they want. To do this, it is mandatory for them to
                  keep the service desk operations running. Generative AI
                  fulfills this service desk goal for managers through enhanced
                  chatbot capabilities, automated workflows, and updated
                  knowledge resources.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workative X Generative AI for Service Desk Managers
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ conversational AI
                </a>{" "}
                is a leading AI solution to help you easily manage your service
                desk operations. Its chatbot builder can access existing service
                desk data across industries, harness domain-specific data, and
                build your KB to train its underlying LLM architecture. Workativ
                also provides hybrid NLU to enrich responses for users as they
                interact with the chatbot regarding service requests.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                When your chatbot is ready, you can sync it with MS Teams or
                Slack and seamlessly facilitate service desk operations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ can integrate with any service desk platform, enabling
                you to create workflow automation and manage service requests at
                scale. Our AI-powered chatbot reduces MTTR by 3X and repetitive
                IT service requests by up to 80%.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To gain maximum benefits from your investment in Generative AI
                and optimize service desk costs, Workativ can be a lucrative
                solution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn more about Workativ,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  get in touch with us.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion:
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generative AI is a trend now, which does not seem to die down
                anytime soon. There are early adopters of the technology and
                those who want to wait and see how they can play with the use
                cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                With that said, the Justice Department’s CIO wants to experiment
                with Generative AI for its existing IT customer service desk and
                make it more responsive and user-friendly. DOJ’s CIO{" "}
                <a href="https://executivegov.com/2023/06/melinda-rogers-generative-ai-could-help-overhaul-doj-it-service-desk/#:~:text=Melinda%20Rogers%3A%20Generative%20AI%20Could%20Help%20Overhaul%20DOJ%20IT%20Service%20Desk,-by%20Naomi%20Cooper&text=Melinda%20Rogers%2C%20chief%20information%20officer,service%20desk%2C%20FedScoop%20reported%20Thursday.">
                  Melinda Rogers sees many opportunities in GenAI to improve
                  their existing service desk and user experience.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                It’s not just Melinda, but thousands of CIOs’ top priority is
                how they can leverage the top benefits of Generative AI in any
                of their business operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If service desk operations look to be a major overhaul for your
                business success, Generative AI is the right solution for you
                and your service desk managers.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                To gain fast time to market with a Generative AI initiative for
                your service desk, Workativ can help you.{" "}
                <a href="/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Generative AI in service desk support operations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How can Generative AI empower service desk managers with
                    service desk operations?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Benefits of Generative AI for service desk operations
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Workative X Generative AI for Service Desk Managers
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Conclusion:
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk operations aren’t level-one services similar to the
                helpdesk that customers or internal users need from a service
                desk. It is not that the service desk does not handle simple
                operations, but the service desk is mostly known for handling
                more complex operations using streamlined workflows 一 and of
                course, focusing on cost optimization.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For service desk managers, cost optimization is a key metric
                that helps determine how effectively and efficiently they manage
                service desk operations without compromising service quality.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, it means the right person handles the ticket,
                provides the right support, and helps in business continuity at
                the right time.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                This is how we can visualize a good service desk to be.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But, the more time it takes to solve a service desk ticket, the
                more costs it adds to per ticket.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The bottom line is service desk managers’ aim is to streamline
                service desk operations and ensure cost-effectiveness for the
                business.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a well-known fact that CIOs have long started leveraging
                artificial intelligence, given AI provides a broader aspect of
                managing tasks more meaningfully and efficiently.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                According to the 2023 Gartner CIO and Technology Executive
                Survey,{" "}
                <a href="https://www.gartner.com/en/articles/what-cios-need-to-know-about-deploying-ai">
                  15% of CIOs surveyed are eager to deploy AI within the next
                  year. It wouldn’t be wrong to say that Generative AI can be
                  the next AI
                </a>{" "}
                target for these CIOs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service desk, being a core component for any enterprise to
                ensure business resilience and continuity, CIOs also hold a
                responsibility to empower service desk managers with adequate
                tools and technologies that help streamline service desk
                operations and ensure cost-effectiveness.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Alike many business leaders, if AI is your next big target, why
                not Generative AI for your service desk managers?
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI in service desk support operations
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let's look at the preliminary side of service desk management.
                It is hard to deny the importance of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-knowledge-management-automation">
                  IT resources or knowledge bases
                </a>{" "}
                that comes in handy for users to get out of trouble by
                themselves or assist others 一 at least in a way that a ticket
                gets resolved in Tier-0 or Tier-1. Effective IT resources can
                reduce calls or emails to the service desk, giving
                auto-resolution capabilities to the users.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                In a legacy model, knowledge resources are not centralized. It
                is even more painful when knowledge is out of date.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The content generation capability can be applied to the IT
                service desk to keep knowledge bases updated. This is just one
                use case of Generative AI for service desk managers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The complex nature of the service desk, where a growing volume
                of tickets is a regular scene, followed by delayed resolution of
                a problem and those repetitive service requests, is only getting
                more complex.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI provides end-to-end management capabilities for
                service desk managers, from IT resource development and
                self-service augmentation through personalized chatbot
                interactions to ticket handling, incident management, and more.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How can Generative AI empower service desk managers with service
                desk operations?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI is a unique innovation of the decade that barely
                needs any convincing explanation as to why industries must use
                it for various use cases. It has already brought a paradigm
                shift to the forefront of the industry and massive disruption to
                how work gets done.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to dive deep to explore Generative AI, our article
                on “Generative AI for Dummies” may be quite useful for you to
                understand this emerging AI technology.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now let us take you through how Generative AI can transform how
                service desk managers handle operations all these years.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Augment the capacity of conversational AI solutions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                What you see is that the conversational AI capability of a
                chatbot is independent. A{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational automation platform
                </a>{" "}
                or engine harnesses natural language processing or natural
                language understanding to extract intents and entities from
                structured or unstructured conversations of users and offer a
                contextual response.
              </p>
              <BlogCta
                ContentCta="Optimize Your IT Support Opereations with Generative AI."
                ButtonText="Book a Demo"
                isColor="white"
                backgroundImage={cta_2}
                mobileBackgroundImage={cta_2_mob}
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                The conversational AI can answer unstructured conversation
                threads by adapting to the environment and continuously
                self-learning.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The conversational experience can be even more personalized and
                straightforward for users to solve their problems when NLP and
                NLU of conversational automation engines are applied with the
                power of Large Language Models (LLMs).
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, when you use Generative AI for your conversational AI
                platform or VA for your users, you can offer enriched responses
                that are not bland, yet fast to absorb and meaningful enough to
                solve a problem without the need to seek help from top tiers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, a regular answer from a conversational AI can
                appear something like below:
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Regular conversation with a conversational AI solution"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                The suggestion is helpful. But, the LLM-powered enriched
                response sound so human-like and personal.
              </p>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="LLM-powered conversations between a user and a bot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                When LLM-powered responses can feel more human and relevant, it
                usually cuts off human dependency and helps solve user problems.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Hyper-automated response suggestions
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say a user question is complicated concerning a product.
                Junior-level support associates likely need help from
                experienced ones. This is again a costly involvement from senior
                level staff and a pain for service desk managers on the cost
                side.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI helps remove this bottleneck from the agent-user
                journey and provides an easy template for the agent to provide
                contextual responses to users.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this context, agents can use LLMs to auto-generate responses
                related to product-specific questions and offer an improved
                suggestion. With the ability to fetch intents from previous
                conversations, Generative AI helps maintain brand tone in the
                ongoing chat session and helps solve customer requests.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this example, let’s represent how a Generative AI-powered
                service desk can automate response and provide the right
                suggestion.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="LLM-powered conversations between a user and a bot "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Another flexibility is that the agent can make some improvements
                to the draft before sending the final response. This is a way
                response automation saves time for agents and helps close the
                tickets rapidly.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Real-time incident management
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="incident management using a Generative AI service desk"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Chances are AIOps can come up with vague clarity due to a lack
                of appropriate data when prompted to suggest an action plan.
                (Though it is solely related to ITSM, we relate to this context
                here because the service desk is a subset of ITSM.)
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                But for service desk managers, incident handling becomes easy
                when they harness Generative AI.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium">
                Generation of incident titles and summaries:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                Creating incident titles and summaries is something that needs
                deep evaluation so that requesters can relay the right
                information and reduce information fatigue. With the ability to
                generate correct content for the titles and summaries,
                Generative AI helps requesters reduce time to create content and
                log the incident.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium">
                Incident analysis and handover for mitigation:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18">
                When an incident is logged in, Generative AI can use an intent
                classifier to synthesize historic incident data and unstructured
                data to identify which incident is to prioritize based on the
                emergency and escalate to the right agent. Using components like
                a deep convolutional neural network, NLU, and intent classifier
                with entity extractors, Generative AI works better than any
                traditional AIOps model to categorize and prioritize incidents.
                Another high-level capability of Generative AI is that it can
                also recommend incident mitigation action plans.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This unique automation capability in the entire workflow of
                incident management reduces the workloads on the service desk
                managers, giving them more time and room to work on critical
                incidents.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automated workflows for service request management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is a huge volume of tickets every day to the service desk
                一 most of them repetitive and mundane. Yet, the service desk
                must spend a lot of time resolving these issues.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Just imagine a situation when an agent does not show up. It
                takes a toll on other agents and adds extra pay for prolonged
                hours of agent utilization.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI can be used to augment service request management
                by streamlining these manual workflows using automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                We already discuss the enhanced capability of a chatbot above.
                To implement and enhance self-service capabilities for the
                service desk chatbot, Generative AI can be instrumental in
                fostering user interest in the tool to resolve a service desk
                issue autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Service requests, such as hardware replacement, software
                installation, or asset provisioning, are repetitive. If you can
                streamline workflows for these tasks, your users can handle them
                easily in the very tier-0 stage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example, a user wants his old laptop replaced.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Instead of seeking the manager’s consent, writing to HR, and
                then communicating back to the inventory department, an
                automated workflow for a Generative-AI powered chatbot can be
                convenient and intuitive.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt=" Flexible IT support for internal employees "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                In this instance, Generative AI is integrated with ERP and many
                other tools in the backend to fetch the real-time stock status
                to the chatbot interface and provide the users with the right
                product information.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is how easily Generative AI helps employees get work done
                faster.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Problem management through enhanced root cause analysis
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the key areas of service desk management is to take care
                of problem management so that managers can prepare themselves
                for unexpected incidents to resolve them.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI allows service desk managers to harness data and
                prepare analytics reports that facilitate root cause analysis of
                an incident. This is helpful for learning across the incident
                patterns and visualizing the future incident's tendency. As a
                result, service desk managers can allocate resources and be
                prepared to mitigate incidents ahead of time.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Seamless service level agreement management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Every workflow in incident management must have the right
                synchronization of connectivity, response, and service delivery.
                For example, if there is a delay in incident identification or
                categorization, the predefined activity in the next of the
                entire workflow gets hampered and impacts service level
                agreement. Unfortunately, this ends up increasing MTTR and
                prolongs downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI helping you manage everything from user
                interaction to chatbot response delivery in a fast-paced service
                desk landscape, you are more empowered to take the situation
                under control and reinstate operational efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Benefits of Generative AI for service desk operations
              </h2>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Streamlined workflows -
                  </span>{" "}
                  Your service desk operations become more streamlined and
                  aligned. Both customers and internal users can experience the
                  flexibility of self-service capabilities, which reduces manual
                  dependencies and offer more straightforward solutions to
                  business problems.
                </li>
                <li className="font-section-normal-text-testimonials">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    {" "}
                    Cost-effectiveness -
                  </span>{" "}
                  As a CIO or business leader of your organization, you can
                  notice your service desk managers are more capable of handling
                  more critical incidents or other service requests steadily.
                  The cost of ticket handling abruptly comes down and encourages
                  bottom-line growth.
                </li>
                <li className="font-section-normal-text-testimonials mb-0">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    Helping attain business goals:
                  </span>{" "}
                  Service desk managers must ensure internal users or customers
                  get what they want. To do this, it is mandatory for them to
                  keep the service desk operations running. Generative AI
                  fulfills this service desk goal for managers through enhanced
                  chatbot capabilities, automated workflows, and updated
                  knowledge resources.
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Workative X Generative AI for Service Desk Managers
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  {" "}
                  Workativ conversational AI
                </a>{" "}
                is a leading AI solution to help you easily manage your service
                desk operations. Its chatbot builder can access existing service
                desk data across industries, harness domain-specific data, and
                build your KB to train its underlying LLM architecture. Workativ
                also provides hybrid NLU to enrich responses for users as they
                interact with the chatbot regarding service requests.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                When your chatbot is ready, you can sync it with MS Teams or
                Slack and seamlessly facilitate service desk operations.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ can integrate with any service desk platform, enabling
                you to create workflow automation and manage service requests at
                scale. Our AI-powered chatbot reduces MTTR by 3X and repetitive
                IT service requests by up to 80%.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To gain maximum benefits from your investment in Generative AI
                and optimize service desk costs, Workativ can be a lucrative
                solution.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to learn more about Workativ,{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  get in touch with us.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion:
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                Generative AI is a trend now, which does not seem to die down
                anytime soon. There are early adopters of the technology and
                those who want to wait and see how they can play with the use
                cases.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                With that said, the Justice Department’s CIO wants to experiment
                with Generative AI for its existing IT customer service desk and
                make it more responsive and user-friendly. DOJ’s CIO{" "}
                <a href="https://executivegov.com/2023/06/melinda-rogers-generative-ai-could-help-overhaul-doj-it-service-desk/#:~:text=Melinda%20Rogers%3A%20Generative%20AI%20Could%20Help%20Overhaul%20DOJ%20IT%20Service%20Desk,-by%20Naomi%20Cooper&text=Melinda%20Rogers%2C%20chief%20information%20officer,service%20desk%2C%20FedScoop%20reported%20Thursday.">
                  Melinda Rogers sees many opportunities in GenAI to improve
                  their existing service desk and user experience.
                </a>
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                It’s not just Melinda, but thousands of CIOs’ top priority is
                how they can leverage the top benefits of Generative AI in any
                of their business operations.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                If service desk operations look to be a major overhaul for your
                business success, Generative AI is the right solution for you
                and your service desk managers.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                To gain fast time to market with a Generative AI initiative for
                your service desk, Workativ can help you.{" "}
                <a href="/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>
            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide - 2023: HR Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023: HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-digital-transformation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Why do you need ITSM in your Digital Trasformation?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
